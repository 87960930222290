// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-appalti-js": () => import("./../../../src/templates/appalti.js" /* webpackChunkName: "component---src-templates-appalti-js" */),
  "component---src-templates-consultazioni-js": () => import("./../../../src/templates/consultazioni.js" /* webpackChunkName: "component---src-templates-consultazioni-js" */),
  "component---src-templates-dettaglio-appalto-js": () => import("./../../../src/templates/dettaglio-appalto.js" /* webpackChunkName: "component---src-templates-dettaglio-appalto-js" */),
  "component---src-templates-dettaglio-consultazione-js": () => import("./../../../src/templates/dettaglio-consultazione.js" /* webpackChunkName: "component---src-templates-dettaglio-consultazione-js" */),
  "component---src-templates-dettaglio-fabbisogno-js": () => import("./../../../src/templates/dettaglio-fabbisogno.js" /* webpackChunkName: "component---src-templates-dettaglio-fabbisogno-js" */),
  "component---src-templates-fabbisogni-js": () => import("./../../../src/templates/fabbisogni.js" /* webpackChunkName: "component---src-templates-fabbisogni-js" */),
  "component---src-templates-index-more-js": () => import("./../../../src/templates/index-more.js" /* webpackChunkName: "component---src-templates-index-more-js" */),
  "component---src-templates-page-generic-js": () => import("./../../../src/templates/page-generic.js" /* webpackChunkName: "component---src-templates-page-generic-js" */)
}

